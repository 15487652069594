import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../service/data.service';
import {MatDialog} from '@angular/material';
import {AuthService} from '../auth.service';
import {DataSource} from '@angular/cdk/table';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
})
export class CardComponent implements OnInit {
  @Input() data: any
  ngOnInit() {
  }
}
