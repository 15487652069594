import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {



  constructor(public router: Router, private route: ActivatedRoute) {

  }

  ngOnInit() {
  }

  onClickMe(value: string) {
    console.log('in Search 1 ' + value);

    this.router.navigate(['/dashboard'], {queryParams: {site: value}}).then(() => {
      window.location.reload();
    });
    // this.router.navigate(['/dashboard', value]);

    // this.router.navigate(['dashboard'], {queryParams: {site: value}}).then(() =>
    //   this.router.navigate(['dashboard']));

    console.log('in Search 2');

  }

}
