import {Component, OnInit} from '@angular/core';
import {PostDialogComponent} from '../post-dialog/post-dialog.component';
import {DataSource} from '@angular/cdk/table';
import {DataService} from '../service/data.service';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material';
import {AuthService} from '../auth.service';
import {BulletinService} from '../service/bulletin.service';
import {IBulletin} from '../service/Interface/IBulletin';

@Component({
  selector: 'app-card-deck',
  templateUrl: './card-deck.component.html',
  styleUrls: ['./card-deck.component.css']
})
export class CardDeckComponent implements OnInit {

  // dataSource = new PostDataSource(this.dataService);
  buleatinboard = [];

  constructor(private dataService: BulletinService, public dialog: MatDialog, public auth: AuthService) {
  }

  ngOnInit() {
    // this.dataService.getData()
    //   .subscribe(data => this.buleatinboard = data);
  }
}

// export class PostDataSource extends DataSource<any> {
//
//   constructor(private dataService: BulletinService) {
//     super();
//   }
//
//   connect(): Observable<IBulletin[]> {
//     return this.dataService.getData();
//   }
//
//   disconnect() {
//   }
// }



