
import {Component, EventEmitter, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {BulletinService} from '../service/bulletin.service';

@Component({
  selector: 'app-post-dialog',
  templateUrl: './post-dialog.component.html',
  styleUrls: ['./post-dialog.component.css']
})
export class PostDialogComponent {
  blogPost = {
    email_title: '',
    title: '',
    description: '',
    site: '',
    email: 'dtgdev@gmail.com',
    date_posted: new Date()
  };
  public event: EventEmitter<any> = new EventEmitter();
  private merged: any;

  constructor(
    public dialogRef: MatDialogRef<PostDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dataService: BulletinService
  ) {
    // this.blogPost.site = this.data.site + '_' + Math.floor(Math.random() * 100) + 1;
    this.blogPost.site = this.data.site ;
    console.log('this.data con :' + JSON.stringify(this.blogPost));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.blogPost.email_title = this.blogPost.email + this.blogPost.title;
    console.log('this.blogPost' + JSON.stringify(this.blogPost));
    this.dataService.addPost(JSON.stringify(this.blogPost));
    this.dialogRef.close();
  }

}


