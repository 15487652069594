import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../service/data.service';
import {Post} from '../service/Interface/Post';
import {DataSource} from '@angular/cdk/table';
import {Observable} from 'rxjs/Observable';
import {AuthService} from '../auth.service';
import {MatDialog} from '@angular/material';
import {PostDialogComponent} from '../post-dialog/post-dialog.component';
import {BulletinService} from '../service/bulletin.service';
import {IBulletin} from '../service/Interface/IBulletin';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import 'rxjs-compat/add/operator/filter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public datasource = [];
  private randomColor: any;
  site: string;
  value: string;
  private _sub: any;
  options: FormGroup;
  public href = '';

  constructor(private dataService: BulletinService, public dialog: MatDialog, public auth: AuthService,
              private route: ActivatedRoute, private router: Router) {

    /*
 force route reload whenever params change;
     this.router.routeReuseStrategy.shouldReuseRoute = () => false;
*/
    this._sub = this.route.queryParams
      .filter(params => params.site)
      .subscribe(params => {
        console.log(params); // {order: "popular"}

        this.site = params.site;
        console.log(`site : ${this.site}`); // popular
      });

    this.dataService.getData(this.site)
      .subscribe(data => this.datasource = data);

  }

  ngOnInit(): void {
    this.href = 'https://www.bulletinspace.com' + this.router.url;
  }

  setMyStyles() {
    const styles = {
      'background-color': this.getRandomColor(),
    };
    return styles;
  }

  getRandomColor() {
    this.randomColor = (function lol(m, s, c) {
      return s[m.floor(m.random() * s.length)] +
        (c && lol(m, s, c - 1));
    })(Math, '3456789ABCDEF', 4);

    // const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + this.randomColor).slice(-6);
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PostDialogComponent, {
      width: '70%',
      data: {site: this.site}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }
}
