import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {IBulletin} from './Interface/IBulletin';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class BulletinService {

  headers = null;
  FOLDER = '';

  // private _url = '/assets/data/bulletin.json';
  private _url = 'https://e2mvton1xd.execute-api.us-west-2.amazonaws.com/dev/bulletinspace';
  // private _url = 'https://e2mvton1xd.execute-api.us-west-2.amazonaws.com/dev/bulletinspace?site=';
  // private _url = 'https://e2mvton1xd.execute-api.us-west-2.amazonaws.com/dev/bulletin';


  constructor(private http: HttpClient) {
    this.http = http;
    this.headers = new Headers();
    // this.headers.append('Content-Type', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append('Content-Type', 'multipart/myGroup-data');
  }
  _getUrl = '?site=';

  /***
   * Get Bulletins fro a given site
   * @param site - the site selected for search
   */
  getData(site: string): Observable<IBulletin[]> {
    this._url = this._url + this._getUrl + site;
    return this.http.get<IBulletin[]>(this._url).pipe(map((data2: any) => {
        return data2.Items;
      } ),
      catchError(error => throwError('Its a Trap!'))
    );
  }

  /***
   * Insert bulletin data to be posted
   * @param data - collected data to be posted
   */
  addPost(data: any) {

    // this.http.post(this._url, data, {headers: this.headers}).subscribe(
    this.http.post(this._url, data).subscribe(
      res  => {
        console.log('POST Request is successful ', res);
      },
      error  => {
        console.log('Error', error);
      }

    );
  }

  deletePost(index) {
    // this.BULLETIN_DATA = [...this.BULLETIN_DATA.slice(0, index), ...this.BULLETIN_DATA.slice(index + 1)];
  }

  dataLength() {
    // return this.BULLETIN_DATA.length;
  }

  /***
   * upload any attached file or picture to s3
   * @param file - this could be file or image
   */
  uploadFile(file) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: 'YOUR-ACCESS-KEY-ID',
        secretAccessKey: 'YOUR-SECRET-ACCESS-KEY',
        region: 'YOUR-REGION'
      }
    );
    const params = {
      Bucket: 'YOUR-BUCKET-NAME',
      Key: this.FOLDER + file.name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      console.log('Successfully uploaded file.', data);
      return true;
    });
// for upload progress
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              console.log('Successfully uploaded file.', data);
              return true;
          });*/
  }
}
